// Translator - A simple class to handle translations
// Loads dictionaries from the environment and finds match

import { common } from './common.js';

export class Translator {
	constructor(lang, dicts = []) {
		this.defaultLang = 'en';
		this.common = [...common];
		this.dict = [...(dicts ?? [])];
		this.lang = lang ?? this.defaultLang;
	}

	// Returns the translation for this.lang and key
	out = (key, interpolations) => {
		// First looks for matches in custom loaded dictionaries
		const dictMatch = this.dict.find((x) => x?.en?.toLowerCase() === key?.toLowerCase())?.[this.lang ?? this.defaultLang];
		if (dictMatch) return this.interpolate(dictMatch, interpolations);

		// Then looks for matches in common dictionary
		const commonMatch = this.common.find((x) => x?.en?.toLowerCase() === key?.toLowerCase())?.[this.lang ?? this.defaultLang];
		if (commonMatch) return this.interpolate(commonMatch, interpolations);

		// Otherwise just returns passed string
		return this.interpolate(key, interpolations);
	};

	// Returns the translation for the passed language and key
	get = (lang, key, interpolations) => {
		// First looks for matches in custom loaded dictionaries
		const dictMatch = this.dict.find((x) => x?.en?.toLowerCase() === key?.toLowerCase())?.[lang ?? this.defaultLang];
		if (dictMatch) return this.interpolate(dictMatch, interpolations);

		// Then looks for matches in common dictionary
		const commonMatch = this.common.find((x) => x?.en?.toLowerCase() === key?.toLowerCase())?.[lang ?? this.defaultLang];
		if (commonMatch) return this.interpolate(commonMatch, interpolations);

		// Otherwise just returns passed string
		return this.interpolate(key, interpolations);
	};

	interpolate = (str, interpolations) => {
		// Interpolations : [{ key, value }]
		if (!interpolations) return str;
		let result = str;
		for (const prop in interpolations) {
			// Replace all instances of prop with value
			result = result.replace(new RegExp(`{${prop}}`, 'gi'), interpolations[prop]);
		}
		return result;
	};
}
